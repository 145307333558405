import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const CookiePolicy = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='About Us' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <p className='text-center text-xl font-bold'>
        Cookie Policy of Budding Superstars
      </p>
      <br/>
      <p style={styles.policyParagraph}>
        This document informs Users about the technologies that help this Website to achieve the purposes described below. Such technologies allow the Owner to access and store information (for example by using a Cookie) or use resources (for example by running a script) on a User’s device as they interact with this Website.
      </p>
      <p style={styles.policyParagraph}>
        For simplicity, all such technologies are defined as "Trackers" within this document – unless there is a reason to differentiate.
        For example, while Cookies can be used on both web and mobile browsers, it would be inaccurate to talk about Cookies in the context of mobile apps as they are a browser-based Tracker. For this reason, within this document, the term Cookies is only used where it is specifically meant to indicate that particular type of Tracker.
      </p>
      <p style={styles.policyParagraph}>
        Some of the purposes for which Trackers are used may also require the User's consent. Whenever consent is given, it can be freely withdrawn at any time following the instructions provided in this document.
        This Website uses Trackers managed directly by the Owner (so-called “first-party” Trackers) and Trackers that enable services provided by a third-party (so-called “third-party” Trackers). Unless otherwise specified within this document, third-party providers may access the Trackers managed by them.
      </p>
      <p style={styles.policyParagraph}>
        The validity and expiration periods of Cookies and other similar Trackers may vary depending on the lifetime set by the Owner or the relevant provider. Some of them expire upon termination of the User’s browsing session.
      </p>
      <p style={styles.policyParagraph}>
        In addition to what’s specified in the descriptions within each of the categories below, Users may find more precise and updated information regarding lifetime specification as well as any other relevant information – such as the presence of other Trackers - in the linked privacy policies of the respective third-party providers or by contacting the Owner.
      </p>
      <br/>

      <p className='text-center text-xl font-bold w-1/2 mx-auto'>
        Activities strictly necessary for the operation of this Website and delivery of the Service
      </p>
      <br/>
      <p style={styles.policyParagraph}>
        This Website uses so-called “technical” Cookies and other similar Trackers to carry out activities that are strictly necessary for the operation or delivery of the Service.
      </p>
      <p style={styles.policyParagraph}>
        <b>Third-party Trackers:</b> <br/>
        iubenda Cookie Solution (consent Cookie) (iubenda srl) <br/>
        iubenda consent Cookie (_iub_cs-X) is set by the iubenda Cookie Solution and stores User preferences related to Trackers in the local domain.<br/>
        Personal Data processed: Tracker. <br/>
        Place of processing: Italy – <a href="https://www.iubenda.com/privacy-policy/252372"><u> Privacy Policy. </u> </a> <br/>
        Storage duration: <br/>
        <p className='ml-10'>
          <li>_iub_cs-*: 1 year </li>
        </p>
      </p>

      <p style={styles.policyHeader}>
        Hosting and backend infrastructure
      </p>
      <p style={styles.policyParagraph}>
        This type of service has the purpose of hosting Data and files that enable this Website to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of this Website.      
      </p>
      <p style={styles.policyParagraph}>
        Some services among those listed below, if any, may work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.<br/>
        SiteGround Hosting (SiteGround Hosting Ltd.): 
        SiteGround Hosting is a hosting service provided by SiteGround Hosting Ltd.
      </p>
      <p style={styles.policyParagraph}>
        Personal Data processed: Tracker, Usage Data and various types of Data as specified in the privacy policy of the service. <br/>     
        Place of processing: United Kingdom – <a href="https://www.siteground.co.uk/privacy.htm"><u> Privacy Policy. </u> </a> <br/>
      </p>

      <br/><p className='text-center text-xl font-bold w-1/2 mx-auto'>
        SPAM protection
      </p> <br/>
      <p style={styles.policyParagraph}>
        This type of service analyzes the traffic of this Website, potentially containing Users' Personal Data, with the purpose of filtering it from parts of traffic, messages and content that are recognized as SPAM.
        <br/>Google reCAPTCHA (Google Ireland Limited)
        <br/>Google reCAPTCHA is a SPAM protection service provided by Google Ireland Limited.
        <br/>The use of reCAPTCHA is subject to the Google privacy policy and terms of use.
        <br/>Personal Data processed: Tracker and Usage Data.
        <br/>Place of processing: Ireland – <a href="https://policies.google.com/privacy"><u> Privacy Policy. </u> </a> 
        <br/>Storage duration: <br/>
        <p className='ml-10'>
          <li>_GRECAPTCHA: duration of the session </li>
          <li>rc::a: indefinite </li>
          <li>rc::b: duration of the session </li>
        </p>
      </p>
      
      <br/><p className='text-center text-xl font-bold w-1/2 mx-auto'>
        Other activities involving the use of Trackers
      </p><br/>
      <p style={styles.policyParagraph}>
        Experience enhancement: 
        This Website uses Trackers to provide a personalized user experience by improving the quality of preference management options, and by enabling interaction with external networks and platforms.
      </p>

      <p style={styles.policyHeader}>
        Displaying content from external platforms
      </p>
      <p style={styles.policyParagraph}>
        This type of service allows you to view content hosted on external platforms directly from the pages of this Website and interact with them. <br/>
        This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it. <br/>
        Google Maps widget (Google Ireland Limited)<br/>
        Google Maps is a maps visualization service provided by Google Ireland Limited that allows this Website to incorporate content of this kind on its pages.<br/>
        Personal Data processed: Tracker and Usage Data. <br/>     
        Place of processing: Ireland – <a href="https://policies.google.com/privacy"><u> Privacy Policy. </u> </a> 
      </p>
      <p style={styles.policyParagraph}>
        Vimeo video (Vimeo, LLC) <br/>
        Vimeo is a video content visualization service provided by Vimeo, LLC that allows this Website to incorporate content of this kind on its pages. <br/>
        Personal Data processed: Tracker and Usage Data. <br/>     
        Place of processing: United States – <a href="https://vimeo.com/privacy"><u> Privacy Policy. </u> </a> 
        <br/>Storage duration: <br/>
        <p className='ml-10'>
          <li>player: 1 year </li>
          <li>vuid: 2 years</li>
        </p>
      </p>
      <p style={styles.policyParagraph}>
        YouTube video widget (Google Ireland Limited) <br/>
        YouTube is a video content visualization service provided by Google Ireland Limited that allows this Website to incorporate content of this kind on its pages. <br/>
        Personal Data processed: Tracker and Usage Data. <br/>     
        Place of processing: Ireland – <a href="https://policies.google.com/privacy"><u> Privacy Policy. </u> </a> 
        <br/>Storage duration: <br/>
        <p className='ml-10'>
          <li>PREF: 8 months </li>
          <li>VISITOR_INFO1_LIVE: 8 months</li>
          <li>YSC: duration of the session</li>
        </p>
      </p>
      <p style={styles.policyParagraph}>
        Measurement: 
        This Website uses Trackers to measure traffic and analyze User behavior with the goal of improving the Service.
      </p>

      <p style={styles.policyHeader}>
        Analytics
      </p>
      <p style={styles.policyParagraph}>
        The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.     
      </p>
      <p style={styles.policyParagraph}>
        Google Analytics (Google Ireland Limited) <br/>
        Google Analytics is a web analysis service provided by Google Ireland Limited (“Google”). Google utilizes the Data collected to track and examine the use of this Website, to prepare reports on its activities and share them with other Google services.
        Google may use the Data collected to contextualize and personalize the ads of its own advertising network. <br/>
        Personal Data processed: Tracker and Usage Data. <br/>     
        Place of processing: Ireland – <a href="https://policies.google.com/privacy"><u> Privacy Policy</u> </a> - <a href="https://tools.google.com/dlpage/gaoptout?hl=en"><u> Opt Out. </u> </a>
        <br/>Storage duration: <br/>
        <p className='ml-10'>
          <li>AMP_TOKEN: 1 hour </li>
          <li>_ga: 2 years</li>
          <li>_gac*: 3 months</li>
          <li>_gat: 1 minute</li>
          <li>_gid: 1 day</li>
        </p>
      </p>

      <br/><p className='text-center text-xl font-bold w-1/2 mx-auto'>
        How to manage preferences and provide or withdraw consent
      </p><br/>
      <p style={styles.policyParagraph}>
        There are various ways to manage Tracker related preferences and to provide and withdraw consent, where relevant:
        Users can manage preferences related to Trackers from directly within their own device settings, for example, by preventing the use or storage of Trackers.
      </p>
      <p style={styles.policyParagraph}>
        Additionally, whenever the use of Trackers is based on consent, Users can provide or withdraw such consent by setting their preferences within the cookie notice or by updating such preferences accordingly via the relevant consent-preferences widget, if available.
        It is also possible, via relevant browser or device features, to delete previously stored Trackers, including those used to remember the User’s initial consent.
      </p>
      <p style={styles.policyParagraph}>
        Other Trackers in the browser’s local memory may be cleared by deleting the browsing history.
        With regard to any third-party Trackers, Users can manage their preferences and withdraw their consent via the related opt-out link (where provided), by using the means indicated in the third party's privacy policy, or by contacting the third party.
      </p>
      <p style={styles.policyParagraph}>
        Locating Tracker Settings<br/>
        Users can, for example, find information about how to manage Cookies in the most commonly used browsers at the following addresses: <br/>
        <p className='ml-10'>
          <li><a href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"><u>Google Chrome</u> </a></li>
          <li><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"><u>Mozilla Firefox</u> </a></li>
          <li><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"><u>Apple Safari</u> </a></li>
          <li><a href="https://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"><u>Microsoft Internet Explorer</u> </a></li>
          <li><a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"><u>Microsoft Edge</u> </a></li>
          <li><a href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing"><u>Brave</u> </a></li>
          <li><a href="https://help.opera.com/en/latest/web-preferences/"><u>Opera</u> </a></li>
        </p>
      </p>
      <p style={styles.policyParagraph}>
        Users may also manage certain categories of Trackers used on mobile apps by opting out through relevant device settings such as the device advertising settings for mobile devices, or tracking settings in general (Users may open the device settings and look for the relevant setting).
      </p>      
      <p style={styles.policyParagraph}>
        Consequences of denying consent: Users are free to decide whether or not to grant consent. However, please note that Trackers help this Website to provide a better experience and advanced functionalities to Users (in line with the purposes outlined in this document). Therefore, in the absence of the User's consent, the Owner may be unable to provide related features.
      </p>   

      <br/><p className='text-center text-xl font-bold w-1/2 mx-auto'>
        Owner and Data Controllers
      </p><br/>
      <p style={styles.policyParagraph}>
        <b>Address:</b><br/>
        Budding Superstars<br/>
        One Tech Hub <br/>
        Schoolhill<br/>
        AB10 1JQ<br/>
        <br/>
        <b>Owner contact email:</b> <br/>
        info@buddingsuperstars.com
      </p> 
      <p style={styles.policyParagraph}>
        Since the use of third-party Trackers through this Website cannot be fully controlled by the Owner, any specific references to third-party Trackers are to be considered indicative. In order to obtain complete information, Users are kindly requested to consult the privacy policies of the respective third-party services listed in this document.
      </p> 
      <p style={styles.policyParagraph}>
        Given the objective complexity surrounding tracking technologies, Users are encouraged to contact the Owner should they wish to receive any further information on the use of such technologies by this Website.      
      </p> 

      <br/><p className='text-center text-xl font-bold w-1/2 mx-auto'>
        Definitions and legal references
      </p><br/>
      <p style={styles.policyParagraph}>
      <b>Personal Data (or Data)</b> <br/>
        Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
      </p> 
      <p style={styles.policyParagraph}>
      <b>Usage Data</b> <br/>
        Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
      </p> 
      <p style={styles.policyParagraph}>
      <b>Personal Data (or Data)</b> <br/>
        Information collected automatically through this Website (or third-party services employed in this Website), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Website, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.      
      </p> 
      <p style={styles.policyParagraph}>
      <b>User</b> <br/>
        The individual using this Website who, unless otherwise specified, coincides with the Data Subject.      
      </p> 
      <p style={styles.policyParagraph}>
      <b>Data Subject</b> <br/>
        The natural person to whom the Personal Data refers.
      </p> 
      <p style={styles.policyParagraph}>
      <b>Data Processor (or Data Supervisor)</b> <br/>
        The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.      
      </p> 
      <p style={styles.policyParagraph}>
      <b>Data Controller (or Owner)</b> <br/>
        The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Website. The Data Controller, unless otherwise specified, is the Owner of this Website.      
      </p> 
      <p style={styles.policyParagraph}>
      <b>This Website (or this Application)</b> <br/>
        The means by which the Personal Data of the User is collected and processed.      
      </p> 
      <p style={styles.policyParagraph}>
      <b>Service</b> <br/>
        The service provided by this Website as described in the relative terms (if available) and on this site/application. 
      </p> 
      <p style={styles.policyParagraph}>
      <b>European Union (or EU)</b> <br/>
        Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.     
      </p> 
      <p style={styles.policyParagraph}>
      <b>Cookie</b> <br/>
        Cookies are Trackers consisting of small sets of data stored in the User's browser.      
      </p> 
      <p style={styles.policyParagraph}>
      <b>Tracker</b> <br/>
        Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting - that enables the tracking of Users, for example by accessing or storing information on the User’s device.      
      </p> 
      <br/>
      <p style={styles.policyHeader}>
        Legal information
      </p>
      <p style={styles.policyParagraph}>
        This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
        This privacy policy relates solely to this Website, if not stated otherwise within this document.
      </p> 
      <Divider space='4' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageCookiePolicyBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/cookie-policy", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default CookiePolicy

